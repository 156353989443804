'use strict'

// import firebase from '../../firebase'
import firebase from '@/firebase'

/**
 * Get user credentials
 * Re-authenticate user
 * @param {*} password 
 * @returns {object}  
 */
function reauthenticate(password) {
	var credential = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, password);
	return firebase.auth().currentUser.reauthenticateWithCredential(credential);
}

const state = {
	profile: null,
  idToken: null,
  invite: null,
};

const getters = {
	profile: state => state.profile,
	idToken: state => state.idToken,
	invite: state => state.invite,
};

const actions = {
	async reAuthenticate(context, payload) {
    try {
      const { password } = payload;
      const {user} = await reauthenticate(password);
      const idToken = await user.getIdToken();
      await context.commit('SET_ID_TOKEN', idToken);
      await context.commit('SET_PROFILE', user);
      await context.dispatch('setMessage', {text: `Re-authentication successful!`, status: true});
      return;
    } catch (error) {
      context.dispatch('setMessage', {text: error.message, status: false});
    }
	},
	async changePassword(context, payload) {
    try {
      const { password, new_password } = payload;
      await reauthenticate(password);
      await firebase.auth().currentUser.updatePassword(new_password);
      await context.dispatch('setMessage', {text: `Password update successful!`, status: true});
      return;
    } catch (err) {
      context.dispatch('setMessage', {text: err.message, status: false});
    }
	},
	async reset(context, payload) {
    try {
      const { email } = payload;
      // send reset password link
      await firebase.auth().sendPasswordResetEmail(email);
      await context.dispatch('setMessage', {text: `Reset link sent to ${payload.email}.`, status: true});
      return;
    } catch (error) {
      context.dispatch('setMessage', {text: error.message, status: false});
    }
	},
	async verify(context, payload) {
		await firebase.auth().currentUser.sendEmailVerification();
		await context.dispatch('setMessage', {text: payload, status: true});
		await context.dispatch('logout');
    return;
	},
	async register(context, payload) {
    try {
      const { displayName, password, email, phoneNumber, agreement } = payload;
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
      if (!user) return await context.dispatch('setMessage', {text: `Unable to complete request!`, status: false});
      // idToken: user._lat
      const idToken = await user.getIdToken();
      await context.commit('SET_ID_TOKEN', idToken);
      await context.dispatch('updateAccount', { displayName, email, phoneNumber, id: user.uid,});
      await context.dispatch('addUser', { displayName, email, phoneNumber, agreement, id: user.uid, });
      await context.dispatch('verify', `Email verification link sent to ${user.email}.`);
      // await context.dispatch('login', { email, password });
      // Promise.resolve('Success');
      return user;
    } catch (err) {
      // Promise.reject('err');
      context.dispatch('setMessage', { message: err.message, status: false });
      return;
    }
	},
  async login(context, payload) {
    const { email, password } = payload;
    try {
      const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
      if (!user) return await context.dispatch('setMessage', {text: `Unable to complete request!`, status: false});
      if (!user.emailVerified) return await context.dispatch('verify', `Kindly verify ${user.email} and re-sign in!`);
      const idToken = await user.getIdToken();
      await context.commit('SET_ID_TOKEN', idToken);
      await context.commit('SET_PROFILE', user);
      await context.dispatch('setMessage', {text: 'Login successful!', status: true});
      return user;
    } catch (err) {
      context.dispatch('setMessage', { text: err.message, status: false });
      return;
    }
  },
  async logout(context) {
    try {
      await context.commit('SET_PROFILE', null);
      await context.commit('SET_ID_TOKEN', null);
      await firebase.auth().signOut();
      await context.dispatch('setMessage', {text: `Logout successful!`, status: true});
      return;
    } catch (error) {
      context.dispatch('setMessage', {text: error.message, status: false});
    }
  },
  async setProfile(context, payload) {
    context.commit('SET_PROFILE', payload);
  },
  async setIdToken(context, payload) {
    context.commit('SET_ID_TOKEN', payload);
  },
  async setInvite(context, payload) {
    context.commit('SET_INVITE', payload);
  },
};

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_ID_TOKEN(state, idToken) {
    state.idToken = idToken;
  },
  SET_INVITE(state, invite) {
    state.invite = invite;
  },
};

export default {
	state,
	getters,
	actions,
	mutations
};