import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import firebase from '../firebase';
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/Register.vue'),
		meta: { guest: true },
	},
	{
		path: '/verify',
		name: 'Verify',
		component: () => import('../views/Verify.vue'),
		meta: { guest: true },
	},
	{
		path: '/reset/password',
		name: 'Reset',
		component: () => import('../views/Reset.vue'),
		meta: { guest: true },
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/confirm',
		name: 'Confirm',
		component: () => import('../views/Confirm.vue'),
		meta: { guest: true },
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views/Dashboard.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/form/:id',
		name: 'Form',
		component: () => import('../views/Form.vue'),
		meta: { guest: true },
		props: true,
		beforeEnter(routeTo, routeFrom, next) {
			store
				.dispatch('getForm', routeTo.params.id)
				.then(form => {
					routeTo.params.form = form;
					next();
				})
				.catch(error => {
					if (error.response && error.response.status == 404) {
						next({ name: '404' });
					} else {
						next({ name: 'Network' });
					}
				});
		},
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import('../views/Terms.vue'),
		meta: { guest: true } 
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: () => import('../views/Privacy.vue'),
		meta: { guest: true } 
	},
	{
		path: '/documentation',
		name: 'Documentation',
		component: () => import('../views/Documentation.vue'),
		children: [
      { path: 'account', name: 'AccountDocumentation', component: () => import('@/components/documentation/AccountDocumentation.vue') },
      { path: 'designer', name: 'DesignerDocumentation', component: () => import('@/components/documentation/DesignerDocumentation.vue') },
      { path: 'insight', name: 'InsightDocumentation', component: () => import('@/components/documentation/InsightDocumentation.vue') },
      { path: 'model', name: 'ModelDocumentation', component: () => import('@/components/documentation/ModelDocumentation.vue') },
      { path: 'preview', name: 'PreviewDocumentation', component: () => import('@/components/documentation/PreviewDocumentation.vue') },
      { path: 'setting', name: 'SettingsDocumentation', component: () => import('@/components/documentation/SettingsDocumentation.vue') },
      { path: 'user', name: 'UserDocumentation', component: () => import('@/components/documentation/UserDocumentation.vue') },
    ],
		meta: { guest: true },
	},
	{
		path: '/404',
		name: 'NotFound',
		component: () => import('../views/NotFound.vue'),
		meta: { guest: true },
		props: true,
	},
	{
		path: '/network',
		name: 'Network',
		component: () => import('../views/Network.vue'),
		meta: { guest: true },
	},
	{
		path: '*',
		redirect: { name: 'NotFound' }
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
	if (requiresAuth && !firebase.auth().currentUser) {
		next({ name: "Login", query: { redirect: to.fullPath } });
	} else {
		next();
	}
});

export default router
