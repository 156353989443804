'use strict'

import { API_URL, util } from '@/services/_Api'
// import axios from 'axios'
// const util = async (url, method, token, payload) => {
// 	return await axios({
// 		method: method,
// 		url: `${url}`,
// 		data: payload,
// 		headers: { 
// 			'content-type': 'application/json',
// 			'Accept': 'application/json',
// 			'Authorization': `Bearer ${token}`, 
// 		},
// 		json: true,
// 	});
// }

export default {
	async addFormImage(idToken, params) {
		return await util(`${API_URL}form/image/addOne`, 'post', idToken, params);
	},
	async addForm(idToken, params) {
		return await util(`${API_URL}form/addOne`, 'post', idToken, params);
	},
	async getForms(idToken) {
		return await util(`${API_URL}form/fetchAll`, 'get', idToken);
	},
	async updateForm(idToken, params) {
		return await util(`${API_URL}form/updateOne/${params.id}`, 'put', idToken, params);
	},
	async getForm(idToken, id) {
		return await util(`${API_URL}form/fetchOne/${id}`, 'get', idToken);
	},
	async deleteForm(idToken, id) {
		return await util(`${API_URL}form/deleteOne/${id}`, 'delete', idToken);
	},
}