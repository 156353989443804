'use strict'

import { API_URL, util } from '@/services/_Api'

export default {
	async addRecordImage(idToken, params) {
		return await util(`${API_URL}record/image/addOne`, 'post', idToken, params);
	},
	async addRecord(idToken, params) {
		return await util(`${API_URL}record/addOne`, 'post', idToken, params);
	},
	async getRecords(idToken) {
		return await util(`${API_URL}record/fetchAll`, 'get', idToken);
	},
	async updateRecord(idToken, params) {
		return await util(`${API_URL}record/updateOne/${params.id}`, 'put', idToken, params);
	},
	async getRecord(idToken, id) {
		return await util(`${API_URL}record/fetchOne/${id}`, 'get', idToken);
	},
	async deleteRecord(idToken, id) {
		return await util(`${API_URL}record/deleteOne/${id}`, 'delete', idToken);
	},
}