<template>
  <div class="footer--wrapper">
    <footer class="footer">
      <div class="footer--items">
        <div class="logo--item">
          <svg class="logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
						<path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
					</svg>
          <router-link class="logo--link" to="/">
            <span class="logo--text">Algoforms © {{ new Date().getFullYear() }}</span>
          </router-link>
        </div>
        <div class="footer--item">
          <ul class="footer--lists">
            <li class="footer--list">
              <router-link class="logo--link" to="/about">About</router-link>
            </li>
            <li class="footer--list">
              <router-link class="logo--link" to="/terms">Terms</router-link>
            </li>
            <li class="footer--list">
              <router-link class="logo--link" to="/privacy">Privacy</router-link>
            </li>
            <li class="footer--list">
              <button class="dark-mode-toggle" aria-label="Enable Dark Mode">
                <svg class="dark-mode-toggle-icon" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-.89 0-1.74-.2-2.5-.55C11.56 16.5 13 14.42 13 12s-1.44-4.5-3.5-5.45C10.26 6.2 11.11 6 12 6c3.31 0 6 2.69 6 6s-2.69 6-6 6z"></path>
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                </svg>
              </button>
              <span class="sr-only">Theme mode</span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Footer",
  methods: {},
};
</script>

<style scoped>
/* footer */
.footer {
  /*display: flex;*/
  /*justify-content: center;*/
  font-family: inherit;
  padding-top: 36px;
  padding-bottom: 36px;
  width: 100%;
  /*background-color: #f0f2f5;*/
}
.footer--items {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0;
  font-family: inherit;
}
.logo--item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
  font-family: inherit;
  /*width: 200px;*/
  min-width: 0;
  max-width: 100%;
}
.logo--icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}
.logo--link {
  align-self: center;
}
.logo--text {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}
.footer--item {
  position: relative;
  display: flex;
  align-self: stretch;
  flex-shrink: 0;
  flex-direction: column;
  font-family: inherit;
  /*min-width: 550px;*/
  max-width: 100%;
  margin-top: 1rem;
}
.footer--lists {
  font-family: inherit;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer--list {
  margin: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-family: inherit;
  flex-basis: 0px;
}
.dark-mode-toggle {
  vertical-align: middle;
}
.dark-mode-toggle-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: rgba(55, 65, 81, 1);
}
.sr-only {
  display: none;
}
/* Wider screen */
@media screen and (min-width: 481px) {
  .home {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
  }
  .footer--items {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    font-family: inherit;
  }
  .footer--item {
    position: relative;
    display: flex;
    align-self: center;
    flex-shrink: 0;
    flex-direction: column;
    font-family: inherit;
    /*min-width: 550px;*/
    max-width: 100%;
    margin-top: 0;
  }
  .footer--lists {
    font-family: inherit;
    flex-wrap: nowrap;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .footer--list {
    margin-left: 1rem;
    margin-right: 1rem;
    font-family: inherit;
  }
}
</style>