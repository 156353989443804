import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from './firebase'
import './registerServiceWorker'

Vue.config.productionTip = false
// Vue.prototype.$token = store.getters.Token;

// scroll element
Vue.directive('scroll', {
	inserted: function(el, binding) {
		let f = function(evt) {
			if (binding.value(evt, el)) {
				window.removeEventListener('scroll', f);
			}
		};
		window.addEventListener('scroll', f);
	},
});

/**
 * Register app
 * Handle page reloads
 * Check if user email is verified
 */
let app
firebase.auth().onAuthStateChanged(async (user) => {
	if (user && user.emailVerified) {
		const idToken = await user.getIdToken();
		await store.dispatch('setProfile', user);
		await store.dispatch('setIdToken', idToken);
	}
		
	if (!app) {
		app = new Vue({
      el: '#app',
      router,
      store: store,
      render: h => h(App)
		})
	}
});