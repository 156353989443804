<template>
	<div class="loader--wrapper">
		<div class="loader" :class="{active: loading }"></div>
    <div class="wave" id="wave">
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
    </div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Loader",
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style scoped>
.loader--wrapper {
  margin: 0;
  padding: 0;
}
.loader {
  background-color: #616565;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
}
.loader.active {
  position: fixed;
}
div#wave {
  position: absolute;
  height: 4rem;
  width: 4rem;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  z-index: 10000;
}
div#wave .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 3px;
  background: #303131;
  animation: wave 1.3s linear infinite;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-15px);
  }
}
/* mini */
@media only screen and (min-width: 461px) {
}
/* max */
@media only screen and (min-width: 964px) {
}
</style>