<template>
	<div class="header--wrapper">
		<header class="header">
      <div class="header--item"></div>
      <div class="header--item">
        <nav class="nav" id="nav">
          <ul class="nav--list">
            <li class="nav--item logo">
              <router-link class="nav--link" to="/">
                <svg class="logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                </svg>
              </router-link>
            </li>
            <!--<li class="nav--item">-->
            <!--  <router-link class="nav--link" to="/home">Home</router-link>-->
            <!--</li>-->
            <li class="nav--item" v-if="profile">
              <router-link class="nav--link" to="/dashboard">Dashboard</router-link>
            </li>
            <!--<li class="nav--item">-->
            <!--  <router-link class="nav--link" to="/customize">Customize</router-link>-->
            <!--</li>-->
            <li class="nav--item">
              <router-link class="nav--link" to="/documentation/account">Doc</router-link>
            </li>
            <li class="nav--item" v-if="!profile">
              <router-link class="nav--link" to="/login">Sign in</router-link>
            </li>
            <li class="nav--item" v-if="profile">
              <router-link class="nav--link" to=""><a @click="onLogout">Log out</a></router-link>
            </li>
            <!--<li class="nav--item">-->
            <!--  <router-link class="nav--link" to="/authenticate">Sign up</router-link>-->
            <!--</li>-->
          </ul>
        </nav>
      </div>
      <div class="header--item"></div>
		</header>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // nav,
    // nav
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    ...mapActions(["logout"]),
    async onLogout() {
      await this.logout();
      // this.$router.push("/login");
      this.$router.push({ name: "Login" });
      // window.location.reload();
    },
  },
};
</script>

<style>
.header {
  margin: 0;
  padding: 0;
}
#nav {
  padding: 10px;
}
.nav .nav--list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
  width: 100%;
}
.nav .nav--item {
  list-style-type: none;
  margin-left: 1rem;
  /*margin-right: 1rem;*/
}
.nav--item .nav--link {
  color: #ffffff;
  color: #000;
  text-decoration: underline;
}
.nav--item.logo {
  flex: auto;
}
/*#nav a {*/
/*	font-weight: bold;*/
/*	color: #2c3e50;*/
/*}*/
/*#nav a.router-link-exact-active {*/
/*	color: #464a48;*/
/*	opacity: .5;*/
/*}*/
.logo--icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}
/* mini */
@media only screen and (min-width: 481px) {
  .nav .nav--item {
    list-style-type: none;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav .nav--list {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .logo--icon {
    width: 5rem;
    height: 5rem;
    fill: currentColor;
  }
}
/* max */
@media only screen and (min-width: 981px) {
  .nav .nav--list {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
  }
  .logo--icon {
    width: 5rem;
    height: 5rem;
    fill: currentColor;
  }
}
</style>
