'use strict'

import accountApi from '@/services/accountApi';

const state = {
	accounts: [],
	account: {},
};

const getters = {
	accounts: state => state.accounts,
	account: state => state.account,
	accountById: state => id => {
		return state.accounts.find(account => account.id === id)
	},
};

const actions = {
	async addAccount(context, payload) {
		try {
			// api call
			const { data } = await accountApi.addAccount(context.rootGetters.idToken, payload);
			await context.dispatch('setMessage', {text: data, status: true});
			return;
		} catch (err) {
			await context.dispatch('setMessage', {message: err.message || `Account add failed!`, status: false});
			return;
		}
	},
	async getAccounts(context) {
    try {
			// api call
      const { data, statusText } = await accountApi.getAccounts(context.rootGetters.idToken);
      await context.commit('GET_ACCOUNTS', data);
      await context.dispatch('setMessage', {text: data.length? 'Accounts fetch successful!' : statusText, status: true});
			return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Account fetch failed!`, status: false});
			return;
    }
	},
	async getAccount(context, payload) {
    try {
			// api call
      const { data, statusText } = await accountApi.getAccount(context.rootGetters.idToken, payload);
      await context.commit('GET_ACCOUNT', data)
      await context.dispatch('setMessage', {text: status === 204? statusText : 'Account fetch successful!', status: true});
			return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Account fetch failed!`, status: false});
			return;
    }
	},
	async updateAccount(context, payload) {
		try {
			// api call
      const {data} = await accountApi.updateAccount(context.rootGetters.idToken, payload);
      await context.commit('UPDATE_ACCOUNT', payload);
      await context.dispatch('setMessage', {text: data || `Account update successful!`, status: true});
      // await context.dispatch('getAccount', payload.id);
			return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Account update failed!`, status: false});
			return;
    }
	},
	async deleteAccount(context, payload) {
    try {
			// api call
      const {data} = await accountApi.deleteAccount(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
			return;
    } catch (err) {
      await context.dispatch('setMessage', {text:  err.message || `User delete failed!`, status: false});
			return;
    }
	},
};

const mutations = {
	ADD_ACCOUNT(state, account) {
		state.accounts.unshift(account);
	},
	GET_ACCOUNT(state, account) {
		state.account = account;
	},
	GET_ACCOUNTS(state, accounts) {
		state.accounts = accounts;
	},
	UPDATE_ACCOUNT(state, payload) {
		const index = state.accounts.findIndex(account => account.id === payload.id);
		if (index !== -1) {
			state.accounts.splice(index, 1, payload);
		}
	},
	DELETE_ACCOUNT(state, payload) {
		state.accounts = state.accounts.filter(account => account.id !== payload.id);
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};