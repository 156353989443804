'use strict'

import formApi from '@/services/formApi';

const state = {
  forms: [],
	form: {},
};

const getters = {
	forms: state => state.forms,
	form: state => state.form,
	formById: state => id => {
		return state.forms.find(form => form.id === id)
	},
};

const actions = {
  async addFormImage(context, payload) {
    try {
      // api call
      const { data } = await formApi.addFormImage(context.rootGetters.idToken, payload)
      await context.dispatch('setMessage', {text: 'Image upload successful!', status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Image upload failed!`, status: false});
      return;
    }
	},
  async addForm(context, payload) {
    try {
      // api call
      const { data } = await formApi.addForm(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
      return;
    } catch (error) {
      await context.dispatch('setMessage', {message: error.message || `Form add failed!`, status: false});
      return;
    }
    
  },
  async getForms(context) {
    try {
      if (context.state.forms && !!context.state.forms.length) return context.state.forms;

      // api call
      const { data, statusText } = await formApi.getForms(context.rootGetters.idToken);
      await context.commit('GET_FORMS', data);
      await context.dispatch('setMessage', {text: data.length? 'Forms fetch successful!' : statusText, status: true});
      return;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Forms fetch failed!`, status: false});
      return;
    }
  },
  async getForm(context, payload) {
    try {
      // api call
      const { data, statusText } = await formApi.getForm(context.rootGetters.idToken, payload);
      await context.commit('GET_FORM', data);
      await context.dispatch('setMessage', {text: status === 204? statusText : 'Form fetch successful!', status: true});
      return data;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Form fetch failed!`, status: false});
      return;
    }
  },
  async updateForm(context, payload) {
    try {
      // api call
      const { data } = await formApi.updateForm(context.rootGetters.idToken, payload);
      await context.commit('UPDATE_FORM', payload);
      await context.dispatch('setMessage', {text: data || `Form update successful!`, status: true});
      await context.dispatch('getForm', payload.id);
      return;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Form update failed!`, status: false});
      return;
    }
    
  },
  async deleteForm(context, payload) {
    try {
      // api call
      const { data } = await formApi.deleteForm(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data || 'Form delete successful!', status: true});
      await context.commit('DELETE_FORM', payload);
      return;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Form delete failed!`, status: false});
      return;
    }
    
  },
};

const mutations = {
  ADD_FORM(state, form) {
    state.forms.unshift(form);
  },
  GET_FORM(state, form) {
    state.form = form;
  },
  GET_FORMS(state, forms) {
    state.forms = forms;
  },
  UPDATE_FORM(state, payload) {
    const index = state.forms.findIndex(form => form.id === payload.id);
    if (index !== -1) {
      state.forms.splice(index, 1, payload);
    }
  },
  DELETE_FORM(state, payload) {
    state.forms = state.forms.filter(form => form.id !== payload);
  },
};

export default {
	state,
	getters,
	actions,
	mutations
};