<template>
  <div class="overlay" :class="{active: overlaying}">
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Overlay",
  computed: {
    ...mapGetters(["overlaying"]),
  },
};
</script>

<style scoped>
/* overlay */
.overlay {
  position: none;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
}
.overlay.active {
  position: fixed;
}
/* .overlay--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.overlay--content {
  margin-left: auto;
}
.overlay--close {
  margin-left: auto;
  cursor: pointer;
}
.overlay--wrapper.success {
  background-color: #c3e8d7;
  color: #078c50;
}
.overlay--wrapper.failure {
  background-color: #ffe5e5;
  color: #ff0000;
} */
</style>