<template>
  <div>
		<Header></Header>
		<div class="home">
			<div class="hero">
				<!--<img alt="Vue logo" src="../assets/logo.png"> -->
				<!--<img alt="Vue logo" src="../assets/hero.svg" style="width: -webkit-fill-available;"> style="width: -webkit-fill-available;"-->
				<img alt="Hero logo" src="../assets/landing.svg" width="100%" height="100%">
				<!--<div class=get--started><router-link class="record" to="/record">Get Started</router-link></div>-->
				<!--<p>Click <router-link class="record" to="/record">Record</router-link> to view initial static template.</p>-->
				<!--<p>This site is down for maintenance, total overhaul ongoing.</p>-->
				<!--<p>Kindly check back later, Thanks</p>-->
			</div>
			<!--<div class="hero">-->
			<!--	<img alt="Hero logo" src="../assets/icon.svg" width="100%" height="100%">-->
			<!--</div>-->
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
/* home */
.home {
  margin: 1rem;
}
.hero {
  height: 85vh;
}
.page--title {
  text-align: center;
  margin: 2rem 0;
}
.get--started {
  position: absolute;
  top: 20%;
  left: 10%;
  font-size: 1rem;
  font-size: 1.5rem;
}
.record {
  color: cornflowerblue;
  text-decoration: solid;
}
/* Wider screen */
@media screen and (min-width: 981px) {
  .home {
    width: 70%;
    margin: 0 auto;
  }
  .hero {
    height: 85vh;
  }
}
</style>