'use strict'

import modelApi from '@/services/modelApi';
import firebase from '../../firebase'

const state = {
  models: [],
  model: {},
  keys: [],
};

const getters = {
	models: state => state.models,
	model: state => state.model,
	modelById: state => id => {
		return state.models.find(model => model.id === id)
	},
	keys: state => state.keys,
};

const actions = {
  async addModelImage(context, payload) {
    try {
      // api call
      const { data } = await modelApi.addModelImage(context.rootGetters.idToken, payload)
      await context.dispatch('setMessage', {text: 'Image upload successful!', status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Image upload failed!`, status: false});
      return;
    }
	},
  async addModel(context, payload) {
    try {
      // api call
      const { data } = await modelApi.addModel(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
      await context.dispatch('getModels');
      return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Model add failed!`, status: false});
      return;
    }
  },
  async getModels(context) {
    try {
      if (context.state.models && !!context.state.models.length) return context.state.models;

      // api call
      const { data } = await modelApi.getModels(context.rootGetters.idToken);
      await context.commit('GET_MODELS', data );
      await context.dispatch('setMessage', {text: data && data.length? 'Models fetch successful!' : 'No data', status: true});
      return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Models fetch failed!`, status: false});
      return;
    }
  },
  async getModel(context, payload) {
    try {
      // api call
      const { data, statusText } = await modelApi.getModel(context.rootGetters.idToken, payload);
      await context.commit('GET_MODEL', data)
      await context.dispatch('setMessage', {text: status === 204? statusText : 'Model fetch successful!', status: true});
      return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Model fetch failed!`, status: false});
      return;
    }
  },
  async updateModel(context, payload) {
    try {
      // api call
      const { data } = await modelApi.updateModel(context.rootGetters.idToken, payload);
      await context.commit('UPDATE_MODEL', payload);
      await context.dispatch('setMessage', {text: data, status: true});
      await context.dispatch('getModel', payload.id);
      return;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Model update failed!`, status: false});
      return;
    }
  },
  async deleteModel(context, payload) {
    try {
      // api call
      const { data } = await modelApi.deleteModel(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
      await context.commit('DELETE_MODEL', payload);
      return;
    } catch (err) {
      await context.dispatch('setMessage', {text:  err.message || `Model delete failed!`, status: false});
      return;
    }
  },
  async sendRequest(context, payload) {
    try {
      // api call
      const sendRequest = firebase.functions().httpsCallable('sendRequest');
      const { data } = await sendRequest(payload);
      await context.dispatch('setMessage', {text: data || `Send invite successful!`, status: true});
      return;
    } catch (err) {
      await context.dispatch('setMessage', {text:  err.message || `Send invite failed!`, status: false});
      return;
    }
  },
  async setKeys(context, payload) {
    context.commit('SET_KEYS', payload);
  }
};

const mutations = {
  ADD_MODEL(state, model) {
    state.models.unshift(model);
  },
  GET_MODEL(state, model) {
    state.model = model;
  },
  GET_MODELS(state, models) {
    state.models = models;
  },
  UPDATE_MODEL(state, payload) {
    const index = state.models.findIndex(model => model.id === payload.id);
    if (index !== -1) {
      state.models.splice(index, 1, payload);
    }
  },
  DELETE_MODEL(state, payload) {
    state.models = state.models.filter(model => model.id !== payload);
  },
  SET_KEYS(state, keys) {
    state.keys = keys;
  },
};

export default {
	state,
	getters,
	actions,
	mutations
};