'use strict'

import { API_URL, util } from '@/services/_Api';

export default {
	async addUserImage(idToken, params) {
		return await util(`${API_URL}user/image/addOne`, 'post', idToken, params);
	},
	async addUser(idToken, params) {
		return await util(`${API_URL}user/addOne`, 'post', idToken, params);
	},
	async getUsers(idToken, params) {
		return await util(`${API_URL}user/fetchAll`, 'get', idToken, '', params);
	},
	async updateUser(idToken, params) {
		return await util(`${API_URL}user/updateOne/${params.id}`, 'put', idToken, params);
	},
	async getUser(idToken, id) {
		return await util(`${API_URL}user/fetchOne/${id}`, 'put', idToken);
	},
	async deleteUser(idToken, id) {
		return await util(`${API_URL}user/deleteOne/${id}`, 'delete', idToken);
	},
}