'use strict'

import { API_URL, util } from '@/services/_Api'

export default {
	async addAccount(idToken, params) {
		return await util(`${API_URL}account/addOne`, 'post', idToken, params);
	},
	async getAccounts (idToken) {
		return await util(`${API_URL}account/fetchAll`, 'get', idToken);
	},
	async updateAccount( idToken, params) {
		return await util(`${API_URL}account/updateOne/${params.id}`, 'put', idToken, params);
	},
	async getAccount(idToken, id) {
		return await util(`${API_URL}account/fetchOne/${id}`, 'get', idToken);
	},
	async deleteAccount(idToken, id) {
		return await util(`${API_URL}account/deleteOne/${id}`, 'delete', idToken);
	},
}