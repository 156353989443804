'use strict'

import userApi from '@/services/userApi';
// import firebase from 'firebase';
import firebase from '../../firebase';

const state = {
  users: [],
	user: {},
};

const getters = {
	users: state => state.users,
	user: state => state.user,
	userById: state => id => {
		return state.users.find(user => user.id === id)
	},
};

const actions = {
  async addUser(context, payload) {
    try {
      // api call
      const { data } = await userApi.addUser(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {message: err.message || `User add failed!`, status: false});
      return;
    }
  },
  async getUsers(context, payload) {
    try {
      // api call
      const { data, statusText } = await userApi.getUsers(context.rootGetters.idToken, payload);
      await context.commit('GET_USERS', data);
      await context.dispatch('setMessage', {text: data.length? 'Users fetch successful!' : statusText, status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `User fetch failed!`, status: false});
      return;
    }
  },
  async getUser(context, payload) {
    // api call
    try {
      const { data, statusText } = await userApi.getUser(context.rootGetters.idToken, payload);
      await context.commit('GET_USER', data)
      await context.dispatch('setMessage', {text: status === 204? statusText : 'User fetch successful!', status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `User fetch failed!`, status: false});
      return;
    }
  },
  async updateUser(context, payload) {
    // api call
    try {
      const { data } = await userApi.updateUser(context.rootGetters.idToken, payload);
      await context.commit('UPDATE_USER', payload);
      await context.dispatch('setMessage', {text: data || `User update successful!`, status: true});
      await context.dispatch('getUser', payload.id);
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `User update failed!`, status: false});
      return;
    }
  },
  async deleteUser(context, payload) {
    // api call
    try {
      const { data } = await userApi.deleteUser(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text:  err.message || `User delete failed!`, status: false});
      return;
    }
  },
  async sendInvite(context, payload) {
    try {
      // api call
      const sendInvite = firebase.functions().httpsCallable('sendInvite');
      const { data } = await sendInvite(payload);
      await context.dispatch('setMessage', {text: data || `Send invite successful!`, status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text:  err.message || `Send invite failed!`, status: false});
      return;
    }
  },
  async confirmRequest(context, payload) {
    try {
      // api call
      const confirmRequest = firebase.functions().httpsCallable('confirmRequest');
      const { data } = await confirmRequest(payload);
      await context.dispatch('setMessage', {text: data || `Accept request successful!`, status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text:  err.message || `Accept request failed!`, status: false});
      return;
    }
  },
};

const mutations = {
  ADD_USER(state, user) {
    state.users.unshift(user);
  },
  GET_USER(state, user) {
    state.user = user;
  },
  GET_USERS(state, users) {
    // users.forEach(user => {
    //   const item = state.users.find(x => x.id === user.id);
    //   if (!item) state.users.push(user);
    //   // console.log(user)
    //   // if (!state.users.includes(user)) state.users.push(user);
    // })
    // return;
    state.users.push(...users);
  },
  UPDATE_USER(state, payload) {
    const index = state.users.findIndex(user => user.id === payload.id);
    if (index !== -1) {
      state.users.splice(index, 1, payload);
    }
  },
  DELETE_USER(state, payload) {
    state.users = state.users.filter(user => user.id !== payload);
  },
};

export default {
	state,
	getters,
	actions,
	mutations
};