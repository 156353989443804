'use strict'

import { API_URL, util } from '@/services/_Api'

export default {
	async addModelImage(idToken, params) {
		return await util(`${API_URL}model/image/addOne`, 'post', idToken, params);
	},
	async addModel(idToken, params) {
		return await util(`${API_URL}model/addOne`, 'post', idToken, params);
	},
	async getModels (idToken) {
		return await util(`${API_URL}model/fetchAll`, 'get', idToken);
	},
	async updateModel(idToken, params) {
		return await util(`${API_URL}model/updateOne/${params.id}`, 'put', idToken, params);
	},
	async getModel(idToken, id) {
		return await util(`${API_URL}model/fetchOne/${id}`, 'get', idToken);
	},
	async deleteModel(idToken, id) {
		return await util(`${API_URL}model/deleteOne/${id}`, 'delete', idToken);
	},
}