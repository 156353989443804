'use strict'

import recordApi from '@/services/recordApi';

const state = {
  records: [],
	record: {},
};

const getters = {
	records: state => state.records,
	record: state => state.record,
	recordById: state => id => {
		return state.records.find(record => record.id === id)
	},
};

const actions = {
  async addRecordImage(context, payload) {
    try {
      // api call
      const { data } = await recordApi.addRecordImage(context.rootGetters.idToken, payload)
      await context.dispatch('setMessage', {text: 'Image upload successful!', status: true});
      return data;
    } catch (err) {
      await context.dispatch('setMessage', {text: err.message || `Image upload failed!`, status: false});
      return;
    }
	},
  async addRecord(context, payload) {
    try {
      // api call
      const { data } = await recordApi.addRecord(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data, status: true});
      return;
    } catch (error) {
      await context.dispatch('setMessage', {message: error.message || `Record add failed!`, status: false});
      return;
    }
    
  },
  async getRecords(context) {
    try {
      // api call
      const { data, statusText } = await recordApi.getRecords(context.rootGetters.idToken);
      await context.commit('GET_RECORDS', data);
      await context.dispatch('setMessage', {text: data.length? 'Records fetch successful!' : statusText, status: true});
      return data;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Records fetch failed!`, status: false});
      return;
    }
  },
  async getRecord(context, payload) {
    try {
      // api call
      const { data, statusText } = await recordApi.getRecord(context.rootGetters.idToken, payload);
      await context.commit('GET_RECORD', data);
      await context.dispatch('setMessage', {text: status === 204? statusText : 'Record fetch successful!', status: true});
      return data;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Record fetch failed!`, status: false});
      return;
    }
  },
  async updateRecord(context, payload) {
    try {
      // api call
      const { data } = await recordApi.updateRecord(context.rootGetters.idToken, payload);
      // await context.commit('UPDATE_RECORD', payload);
      await context.dispatch('setMessage', {text: data || `Record update successful!`, status: true});
      // await context.dispatch('getRecord', payload.id);
      return;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Record update failed!`, status: false});
      return;
    }
    
  },
  async deleteRecord(context, payload) {
    try {
      // api call
      const { data } = await recordApi.deleteRecord(context.rootGetters.idToken, payload);
      await context.dispatch('setMessage', {text: data || 'Record delete successful!', status: true});
      await context.commit('DELETE_RECORD', payload);
      return;
    } catch (error) {
      await context.dispatch('setMessage', {text: error.message || `Record delete failed!`, status: false});
      return;
    }
    
  },
};

const mutations = {
  ADD_RECORD(state, record) {
    state.records.unshift(record);
  },
  GET_RECORD(state, record) {
    state.record = record;
  },
  GET_RECORDS(state, records) {
    state.records = records;
  },
  UPDATE_RECORD(state, payload) {
    const index = state.records.findIndex(record => record.id === payload.id);
    if (index !== -1) {
      state.records.splice(index, 1, payload);
    }
  },
  DELETE_RECORD(state, payload) {
    state.records = state.records.filter(record => record.id !== payload);
  },
};

export default {
	state,
	getters,
	actions,
	mutations
};