'use strict'

// Axios
import axios from 'axios'
// Api url
export const API_URL = 'https://algoforms.uc.r.appspot.com/api/v1/';
// export const API_URL = 'http://localhost:8080/api/v1/';

export const util = async (url, method, token, payload, query) => {
  return await axios({
    method: method,
    url: `${url}`,
    data: payload,
    params: query,
    headers: {
      'content-type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    json: true,
  });
};