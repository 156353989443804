'use strict'

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
// import 'firebase/storage';     // for storage
// import 'firebase/database';    // for realtime database
// import 'firebase/firestore';   // for cloud firestore
// import 'firebase/messaging';   // for cloud messaging

// Initialize Firebase configuration. For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebase.initializeApp({
	apiKey: "AIzaSyDu8nGKsucVBsBeWR5RaDwFeYx6jl-E_pQ",
  authDomain: "algoforms.firebaseapp.com",
  projectId: "algoforms",
  storageBucket: "algoforms.appspot.com",
  messagingSenderId: "1083534737346",
  appId: "1:1083534737346:web:f9890c2adc4c1b16aee05b",
  measurementId: "G-8D040S9G6Y"
})

export default firebase;