import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account';
import auth from './modules/auth';
import form from './modules/form';
import record from './modules/record';
import model from './modules/model';
import user from './modules/user';

Vue.use(Vuex)

// const store = new Vuex.Store({
export default new Vuex.Store({
  state: {
    message: { text: '', status: false,},
		loading: false,
		overlaying: false,
		roles: [{id: 1, name: 'user', value: true}, {id: 2, name: 'co-owner', value: true}, {id: 3, name: 'owner', value: true}],
  },
  getters: {
    message: state => state.message,
		loading: state => state.loading,
		overlaying: state => state.overlaying,
    roles: state => state.roles,
  },
  actions: {
		async setMessage({ commit }, payload) {
			commit('SET_MESSAGE', payload);
		},
    async setLoading({ commit }, payload) {
			commit('SET_LOADING', payload);
		},
    async setOverlaying({ commit }, payload) {
			commit('SET_OVERLAYING', payload);
		},
  },
  mutations: {
		SET_MESSAGE(state, payload) {
			state.message.text = payload.text || '';
			state.message.status = payload.status || '';
		},
    SET_LOADING(state, loading) {
			state.loading = loading;
		},
    SET_OVERLAYING(state, overlaying) {
			state.overlaying = overlaying;
		},
  },
  modules: {
    account,
    auth,
    form,
    record,
    model,
    user,
  }
})

// export default store;